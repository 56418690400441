<template>
  <v-container fluid>
    <loader-data-table
      v-if="isLoading"
      :columns="6"
      :rows="25"
      :verification="true"
    />

    <v-data-table
      :headers="headers"
      :items="employees"
      :items-per-page="25"
      class="elevation-3 mx-lg-10"
      mobile-breakpoint="100"
      calculate-widths
      hide-default-footer
    >
      <template v-slot:top>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="px-8 py-8"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari"
              color="purple"
              single-line
              hide-details
              clearable
              @keyup.enter.native="filterData"
              @click:append="filterData"
              @click:clear="filterClear"
            />
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="px-8 py-8 text-right"
          >
            <v-btn
              v-if="isAllowCreate"
              color="purple"
              dark
              @click="addButtonClick"
            >
              TAMBAH PEGAWAI
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        {{ item.fname }} {{ }} {{ item.lname }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-tooltip
          color="purple"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              id="switch-container"
              class="d-inline-flex"
              v-bind="attrs"
              v-on="on"
            >
              <v-switch
                v-model="item.status"
                :false-value="2"
                :true-value="1"
                inset
                :disabled="!isAllowEdit"
                color="purple"
                @change="changeStatus(item.hash)"
              />
            </div>
          </template>
          <span v-if="item.status === 1">Non-Aktifkan</span>
          <span v-if="item.status === 2">Aktifkan</span>
        </v-tooltip>
      </template>

      <template v-slot:footer>
        <div class="text-center my-5 py-3">
          <v-pagination
            v-model="page"
            :length="pageCount"
            color="purple"
            @input="onChangePage"
          />
          <span style="font-size: 12px; font-style: italic;">
            Page {{ page }} of {{ pageCount || 1 }}
          </span>
        </div>
        <v-row class="mt-n16">
          <v-col class="text-right pr-8">
            <span style="font-size: 12px; font-style: italic;">
              {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
            </span>
          </v-col>
        </v-row>
      </template>

      <template
        v-slot:[`item.actions`]="{ item }"
      >
        <v-btn
          small
          dark
          color="purple"
          class="mr-2"
          @click="detailButtonClick(item)"
        >
          DETAIL
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    components: {
      LoaderDataTable: () => import('../../component/LoaderDataTable'),
    },
    data: () => ({
      search: '',
      headers: [
        { text: 'Nama', align: 'start', value: 'name' },
        { text: 'Telepon', value: 'phone' },
        { text: 'Divisi', value: 'pos_divisi' },
        { text: 'Posisi', value: 'pos_name' },
        { text: 'Email Travikr', value: 'email', sortable: false },
        { text: 'Domisili', value: 'address_string', sortable: false },
        { text: 'Status', value: 'status' },
        { text: '', value: 'actions', sortable: false },
      ],
      employees: [],
      isAllowCreate: false,
      isAllowEdit: false,
      page: 1,
      pageCount: 0,
      isLoading: false,
      itemCount: 0,
      itemTotal: 0,
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),
    },

    watch: {
    },

    created () {
      this.initialize(1, this.itemsPerPage)
    },

    methods: {
      async initialize (page, limit, filter) {
        this.isLoading = true

        // Check user menus
        const userMenus = localStorage.getItem('userMenus').split(',')
        if (userMenus.includes('EMPLOYEE_CREATE')) {
          this.isAllowCreate = true
        }
        if (userMenus.includes('EMPLOYEE_EDIT')) {
          this.isAllowEdit = true
        }

        // this.isLoading = true

        const vm = this
        const requestBody = {
          page: page,
          limit: limit,
          searchq: filter,
        }
        await axios.post('/t/employee', this.$qs.stringify(requestBody)).then((res) => {
          if (res.data.status === 200) {
            vm.employees = res.data.data.list
            vm.pageCount = res.data.data.pagination.page_total
            vm.itemCount = res.data.data.pagination.item_count
            vm.itemTotal = res.data.data.pagination.item_count_total
          }
        }).catch((e) => {
        })

        this.isLoading = false
      },

      changeStatus (hash) {
        const requestBody = new URLSearchParams()
        requestBody.append('emp_hash', hash)

        axios.post('/t/employee/mod/status', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
      },

      onChangePage (e) {
        this.initialize(e, this.itemsPerPage)
      },

      detailButtonClick (item) {
        this.$router.push({ name: 'EmployeeDetail', params: { id: item.hash } })
      },

      addButtonClick () {
        this.$router.push({ name: 'EmployeeAdd' })
      },

      filterData () {
        this.initialize(1, this.itemsPerPage, this.search)
      },

      filterClear () {
        this.initialize(1, this.itemsPerPage)
      },
    },
  }
</script>
